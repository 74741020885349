import "./App.css";
import "./fonts/Griffon.ttf"

function App() {
  return (
    <div className="wrapper">
      <div className="container">
        <h1>Sitio en Construcción</h1>
        <p>Estamos trabajando en algo increíble. ¡Volveremos pronto!</p>
      </div>
      <div className="footer">
        <div>
          <p className="company_name">CONTROL DE CALIDAD</p>
        </div>
        <div>
          <p className="company_name">Y GEOTECNIA S.A DE C.V</p>
        </div>
      </div>
    </div>
  );
}

export default App;
